<template>
  <div>
    <div v-swiper:Thumbnails="thumbnails">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img src="https://swiperjs.com/demos/images/nature-1.jpg" />
        </div>
        <div class="swiper-slide">
          <img src="https://swiperjs.com/demos/images/nature-1.jpg" />
        </div>
        <div class="swiper-slide">
          <img src="https://swiperjs.com/demos/images/nature-1.jpg" />
        </div>
      </div>
      <!-- <div class="cover-pagination swiper-pagination"></div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'thumbnails',
  data () {
    return {
      thumbnails: {
        spaceBetween: 10,
         slidesPerView: 1,
         freeMode: true,
         watchSlidesProgress: true,

      },
    }
  },
}
</script>
