<template>
  <div class="relative" v-swiper:Cover="cover">
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="cover-pagination swiper-pagination pt-12"></div>
<div class="flex justify-center absolute bottom-1 lg:-bottom-1 w-full gap-x-64">
  <div class="swiper-button-prevs z-10">
    <svg xmlns="http://www.w3.org/2000/svg" width="79.8" height="79.8" viewBox="0 0 79.8 79.8" class="transform rotate-180 w-10 h-10 lg:w-14 lg:h-14">
      <g id="Arrow-right-01-01" transform="translate(-0.1 -0.1)">
        <g id="Group_205" data-name="Group 205">
          <path id="Path_2705" data-name="Path 2705" fill="#fff" d="M40,3.1A36.9,36.9,0,1,1,3.1,40,36.938,36.938,0,0,1,40,3.1m0-3A39.9,39.9,0,1,0,79.9,40,39.946,39.946,0,0,0,40,.1Z"/>
        </g>
        <g id="Group_206" data-name="Group 206">
          <line id="Line_6174" data-name="Line 6174" x1="53.4" transform="translate(13.2 39.9)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
          <path id="Path_2706" data-name="Path 2706" d="M51.5,24.7,66.8,40,51.5,55.3" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
        </g>
      </g>
    </svg>

  </div>
  <div class="swiper-button-nexts z-10">
    <svg xmlns="http://www.w3.org/2000/svg" width="79.8" height="79.8" viewBox="0 0 79.8 79.8" class="w-10 h-10 lg:w-14 lg:h-14">
      <g id="Arrow-right-01-01" transform="translate(-0.1 -0.1)">
        <g id="Group_205" data-name="Group 205">
          <path id="Path_2705" data-name="Path 2705" fill="#fff" d="M40,3.1A36.9,36.9,0,1,1,3.1,40,36.938,36.938,0,0,1,40,3.1m0-3A39.9,39.9,0,1,0,79.9,40,39.946,39.946,0,0,0,40,.1Z"/>
        </g>
        <g id="Group_206" data-name="Group 206">
          <line id="Line_6174" data-name="Line 6174" x1="53.4" transform="translate(13.2 39.9)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
          <path id="Path_2706" data-name="Path 2706" d="M51.5,24.7,66.8,40,51.5,55.3" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
        </g>
      </g>
    </svg>

  </div>
</div>
  </div>
</template>
<script>
export default {
  name: 'ServiceSlider',
  data () {
    return {
      cover: {
        mousewheelControl: false,
        spaceBetween: 40,
        slidesPerView: 1,
        loop:true,
        mousewheel:false,
        navigation: {
         nextEl: '.swiper-button-nexts',
         prevEl: '.swiper-button-prevs',
        },
        preventClicks: true,
        pagination: {
          clickable: true,
          el: '.cover-pagination'
        },
        breakpoints: {
          992: {
            spaceBetween: 50,
            slidesPerView: 3,
          }
        },
      },
    }
  },
}
</script>
