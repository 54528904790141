<template>
  <div>
    <div v-swiper:Cover="cover">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img src="https://swiperjs.com/demos/images/nature-1.jpg" />
        </div>
        <div class="swiper-slide">
          <img src="https://swiperjs.com/demos/images/nature-1.jpg" />
        </div>
        <div class="swiper-slide">
          <img src="https://swiperjs.com/demos/images/nature-1.jpg" />
        </div>
      </div>
      <!-- <div class="cover-pagination swiper-pagination"></div> -->
      <div class="swiper-button-next"></div>
 <div class="swiper-button-prev"></div>
    </div>
    <thumbs-slider></thumbs-slider>
  </div>
</template>
<script>
export default {
  name: 'GallerySlider',
  data () {
    return {
      cover: {
       spaceBetween: 10,
       navigation: {
         nextEl: ".swiper-button-next",
         prevEl: ".swiper-button-prev",
       },
       thumbs: {
         swiper: Thumbnails,
       },
      },
    }
  },
}
</script>
