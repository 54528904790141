<template>
  <div v-swiper:Cover="cover">
    <div class="swiper-wrapper">
      <slot />
    </div>
    <!-- <div class="cover-pagination swiper-pagination"></div> -->
  </div>
</template>
<script>
export default {
  name: 'LogoSlider',
  data () {
    return {
      cover: {
        direction: "horizontal",
        autoplay: { delay: 2000 },
        loop: true,
        loopAdditionalSlides: 5,
        spaceBetween: 20,
        slidesPerView: 2,
        breakpoints: {
          600: {
            slidesPerView: 5.1,
            spaceBetween: 40,
          }
        }
      }
    }
  },
}
</script>
